import React from "react";
import { DmTableParticipant, DmPosition } from "../../client/server-types-python";
import styled from "styled-components";
import { getName } from "../../client/util";
import theme from "../../theme";
import { getAvatarUrl, getBotAvatarUrl } from "../../misc/Avatars";
import bet_mask from "../../assets/butterfly.svg";

export enum Alignment {
    BOTTOM = "bottom",
    LEFT = "left",
    TOP = "top",
    RIGHT = "right",
}

const BetImage = styled.img`
    width: 32px;
    height: 32px;
    margin-left: 2px;
    margin-right: 2px;
    opacity: 0.8;
`;

const FinishedOrderDiv = styled.div`
    margin-left: 2px;
    margin-right: 2px;
    background-color: white;
    border-radius: 50%;
    padding: 1px 1px;
    width: 25px;
    height: 25px;
    font-size: 18px;
    color: black;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
`;

const SeatDiv = styled.div<{ $alignment: Alignment }>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0px solid black;
    margin: 12px;
    border-radius: 8px;
    font-size: 36px;
    color: white;
    ${(props) => {
        switch (props.$alignment) {
            case Alignment.BOTTOM:
                return "width: 514px; height: 122px;";
            case Alignment.LEFT:
                return "height: 514px; width: 122px; flex-direction: row-reverse;";
            case Alignment.TOP:
                return "width: 514px; height: 122px;";
            case Alignment.RIGHT:
                return "height: 514px; width: 122px; flex-direction: row;";
        }
    }}
`;

const PlayerInfoWrapper = styled.div<{ $alignment: Alignment }>`
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Ensure content stays within bounds */
    ${(props) => {
        switch (props.$alignment) {
            case Alignment.BOTTOM:
            case Alignment.TOP:
                return "width: 100%; height: 60px;";
            case Alignment.LEFT:
            case Alignment.RIGHT:
                return "height: 100%; width: 60px;";
        }
    }}
`;

const AvatarDiv = styled.div<{ $teamColor: string }>`
    width: 36px;
    height: 36px;
    background-color: ${(props) => props.$teamColor};
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const AvatarImage = styled.img`
    width: 80%;
    height: 80%;
    object-fit: cover;
    opacity: 0.8;
`;

const PlayerInfoDiv = styled.div<{ $active: boolean; $alignment: Alignment; $teamColor: string }>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 0px solid black;
    margin: 12px;
    border-radius: 8px;
    font-size: 36px;
    color: white;
    background-color: ${(props) => props.$teamColor};
    padding: 0px;

    // add a glow around it if active
    ${(props) => (props.$active ? "box-shadow: 0 0 8px 5px #ffd856;" : "")}
    ${(props) => {
        switch (props.$alignment) {
            case Alignment.BOTTOM:
                return "";
            case Alignment.LEFT:
                return "transform: rotate(90deg);";
            case Alignment.TOP:
                return "";
            case Alignment.RIGHT:
                return "transform: rotate(-90deg);";
        }
    }}
`;

const PlayerInfoLabel = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 0px solid black;
    margin: 2px 4px 2px 4px;
    font-size: 20px;
    color: black;
    white-space: nowrap;
`;

const PlayerInfoText = styled.span`
    margin: 0px 6px;
`;

const LimboCardsDiv = styled.div`
    position: absolute;
    top: -89px;
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 12px;
    font-size: 36px;
    color: white;
`;

export class Seat extends React.Component<{
    player: DmTableParticipant | null;
    position: DmPosition;
    active: boolean;
    alignment: Alignment;
    hand: React.ReactNode;
    limboCards?: React.ReactNode;
    nsTeam: boolean;
}> {
    render() {
        const { player, active, alignment, hand, limboCards, nsTeam } = this.props;
        const teamColor = nsTeam ? theme.colors.ns_color : theme.colors.ew_color;
        const teamColorLight = nsTeam ? theme.colors.ns_color_light : theme.colors.ew_color_light;
        const avatarUrl = player?.user?.avatar ? getAvatarUrl("", player.user.avatar) : getBotAvatarUrl("");

        const activeBet = this.props.position.bet;
        const finishedOrder = this.props.position.finished_order;

        return (
            <SeatDiv $alignment={alignment}>
                {limboCards && <LimboCardsDiv>{limboCards}</LimboCardsDiv>}
                {alignment === Alignment.TOP && hand}
                <PlayerInfoWrapper $alignment={alignment}>
                    <PlayerInfoDiv $active={active} $alignment={alignment} $teamColor={teamColorLight}>
                        <AvatarDiv $teamColor={teamColor}>
                            <AvatarImage src={avatarUrl} alt="Player avatar" />
                        </AvatarDiv>
                        <PlayerInfoLabel>
                            <PlayerInfoText>{getName(player)}</PlayerInfoText>
                            {activeBet ? <BetImage src={bet_mask} alt="Bet" /> : null}
                            {finishedOrder ? <FinishedOrderDiv>{finishedOrder}</FinishedOrderDiv> : null}
                        </PlayerInfoLabel>
                    </PlayerInfoDiv>
                </PlayerInfoWrapper>
                {alignment !== Alignment.TOP && hand}
            </SeatDiv>
        );
    }
}
