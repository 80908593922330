import { useEffect, useState, useRef } from "react";
import { WebSocketClient } from "../client/WebSocketClient";
import { ApiClient } from "../client/ApiClient";
import { MoveUtil } from "../model/MoveUtil";
import { Card, RANKS } from "./game/Card";
import { CardDetails } from "../model/CardDetails";
import { Hand } from "./game/Hand";
import { Alignment, Seat } from "./game/Seat";
import { SeatActivePlay } from "./game/SeatActivePlay";
import { Pass } from "./game/Pass";
import {
    CombinationType,
    DmGame,
    DmTable,
    DmUser,
    DmTradeSlateChoice,
    DmHistoryEntry,
    DmCombination,
    DmPlayState,
    GameStatus,
    DmTableSettings,
} from "../client/server-types-python";
import { CardId, PosId, Rank } from "../client/basic-types";
import { GameLayout } from "./GameLayout";
import { TrickInfo } from "./game/TrickInfo";
import { HistoryPanel } from "./game/HistoryPanel";
import { NowplayState, ReplayState } from "../model/ReplayState";
import styled from "styled-components";
import { TradeCardSlot } from "./TradeCardSlot";
import { getHandHistory } from "../model/HandHistoryUtil";
import { LoadingDiv } from "./LoadingDiv";
import { playBigDrum, playDrum, playPop } from "../misc/SoundPlayer";
import { MoveAssistant } from "../model/MoveAssistant";
import { GameRequest } from "./GameRequest";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import { TableSetup } from "./TableSetup";
import { ReplayBar } from "./game/ReplayBar";

export type TableProps = {
    apiClient: ApiClient;
    webSocketClient: WebSocketClient | null;
    user: DmUser;
    tableId: string;
    tablesState: DmTable[];
    game: DmGame | null;
};

const TradeArea = styled.div`
    width: 160px;
    height: 60px;
    border: 1px dashed lightgray;
    border-radius: 5px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 8px;
`;

const Controls = styled(motion.div)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const RequestAcceptButton = styled.button`
    background-color: ${(props) => props.theme.colors.action_positive};
    color: white;
    margin: 4px;
    border-radius: 8px;
    border: 1px solid darkgray;
    width: 60px;
    height: 30px;

    &:hover {
        background-color: ${(props) => props.theme.colors.action_positive_hover};
    }
`;

const RequestDeclineButton = styled.button`
    background-color: ${(props) => props.theme.colors.action_negative};
    color: white;
    margin: 4px;
    border-radius: 8px;
    border: 1px solid darkgray;
    width: 60px;
    height: 30px;

    &:hover {
        background-color: ${(props) => props.theme.colors.action_negative_hover};
    }
`;

export const Table = ({ apiClient, webSocketClient, user, tableId, tablesState, game }: TableProps): JSX.Element => {
    const table = tablesState.find((table) => table.id === tableId)!;
    const playerSeat: PosId | -1 = table.players.findIndex((player) => player?.user?.id === user.id) as PosId | -1;

    const [selectedCards, setSelectedCards] = useState<CardId[]>([]);
    // Cards that the user has asked to play, but we don't yet have confirmation from the server
    const [playLimboCards, setPlayLimboCards] = useState<CardId[]>([]);
    // Whether the user has submitted a move and is waiting for the server to confirm
    const [waitingOnSubmittedMove, setWaitingOnSubmittedMove] = useState(false);
    const [tradeSlateCards, setTradeSlateCards] = useState<(CardId | null)[]>([null, null, null]);
    const [tradeSlateDirty, setTradeSlateDirty] = useState(false);
    const [wishValue, setWishValue] = useState<Rank | undefined | "No wish">(undefined);
    const [replayState, setReplayState] = useState<ReplayState>({
        replayModeEnabled: false,
        replayHandCursor: 0,
        replayMoveCursor: 0,
    });
    const exitReplayMode = () => {
        setReplayState({
            replayModeEnabled: false,
            replayHandCursor: 0,
            replayMoveCursor: 0,
        });
    };
    const [nowplayState, setNowplayState] = useState<NowplayState>({
        nowplayHandCursor: game ? game.game_hands.length - 1 : -1,
        nowplayMoveCursor: game ? (game.game_hands[game.game_hands.length - 1]?.history?.length ?? 0) - 1 : -1,
        lastUpdateTime: Date.now(),
    });
    const [handHistoryCache, setHandHistoryCache] = useState<(DmHistoryEntry[] | null)[]>([]);
    const [suggestedPlays, setSuggestedPlays] = useState<DmCombination[]>([]);

    const nowplayAdvanceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    // When tableId changes, reset the history cache
    useEffect(() => {
        setHandHistoryCache([]);
    }, [tableId]);

    // Fetch replay state if pointing at an old hand
    useEffect(() => {
        if (replayState.replayModeEnabled && game && getHandHistory(game, replayState.replayHandCursor, handHistoryCache) === null) {
            const response = apiClient.getGameHand(tableId, replayState.replayHandCursor);
            response.then((response) => {
                if (response instanceof Error) {
                    console.error(response);
                } else {
                    const newHandHistoryCache = [...handHistoryCache];
                    while (newHandHistoryCache.length <= replayState.replayHandCursor) {
                        newHandHistoryCache.push(null);
                    }
                    newHandHistoryCache[replayState.replayHandCursor] = response.history;
                    setHandHistoryCache(newHandHistoryCache);
                }
            });
        }
    }, [tableId, game, replayState, handHistoryCache, apiClient]);

    const nowplayStepDelay = [1500, 1100, 700, 400, 100][user.user_settings?.speed ?? 2];

    // Update nowplay state
    useEffect(() => {
        if (game === null) {
            return;
        }
        if (game.status === GameStatus.NOT_STARTED) {
            if (nowplayState.nowplayHandCursor !== -1 || nowplayState.nowplayMoveCursor !== -1) {
                setNowplayState({
                    nowplayHandCursor: -1,
                    nowplayMoveCursor: -1,
                    lastUpdateTime: Date.now(),
                });
            }
            return;
        }
        const playSoundForStateTransition = (prevState: DmPlayState | null, newState: DmPlayState) => {
            if (newState.is_hand_over && (prevState === null || !prevState.is_hand_over)) {
                playBigDrum();
            }

            const wasActiveNonEngine = prevState !== null && prevState.active_player === playerSeat && !prevState.is_engine_turn;
            const isActiveNonEngine = newState.active_player === playerSeat && !newState.is_engine_turn;

            if (isActiveNonEngine && !wasActiveNonEngine) {
                playDrum();
            } else {
                playPop();
            }
        };
        const prevState =
            nowplayState.nowplayHandCursor === -1 ||
            nowplayState.nowplayMoveCursor === -1 ||
            nowplayState.nowplayHandCursor >= game.game_hands.length ||
            nowplayState.nowplayMoveCursor >= (getHandHistory(game, nowplayState.nowplayHandCursor, handHistoryCache) ?? []).length
                ? null
                : getHandHistory(game, nowplayState.nowplayHandCursor, handHistoryCache)![nowplayState.nowplayMoveCursor].state;
        console.log("Current time: " + Date.now() + " ~ Last update time: " + nowplayState.lastUpdateTime);
        const maxHandCursor = game.game_hands.length - 1;
        const maxMoveCursor = (game.game_hands[maxHandCursor]?.history?.length ?? 0) - 1;

        const advanceState = (mute: boolean = false) => {
            console.log(nowplayState.nowplayMoveCursor + 1 + " Execute on delay");
            setNowplayState((prevState) => ({
                ...prevState,
                nowplayMoveCursor: prevState.nowplayMoveCursor + 1,
                lastUpdateTime: Date.now(),
            }));
            const newState = game.game_hands[nowplayState.nowplayHandCursor].history![nowplayState.nowplayMoveCursor + 1].state;
            if (!mute) {
                playSoundForStateTransition(prevState, newState);
            }
        };

        if (nowplayState.nowplayHandCursor !== maxHandCursor) {
            // If we're not on the latest hand, jump to the end of the latest hand
            setNowplayState({
                nowplayHandCursor: maxHandCursor,
                nowplayMoveCursor: maxMoveCursor,
                lastUpdateTime: Date.now(),
            });
            console.log(nowplayState.nowplayMoveCursor + 1 + " Jumping to latest hand");
            const newState = game.game_hands[maxHandCursor].history![maxMoveCursor].state;
            playSoundForStateTransition(prevState, newState);
        } else if (nowplayState.nowplayMoveCursor < maxMoveCursor) {
            // If we're on the right hand but behind, advance one move at a time
            const wasActiveNonEngine = prevState !== null && prevState.active_player === playerSeat && !prevState.is_engine_turn;
            if (wasActiveNonEngine) {
                advanceState(true);
            } else if (Date.now() - nowplayState.lastUpdateTime > nowplayStepDelay) {
                advanceState();
            } else {
                if (nowplayAdvanceTimeoutRef.current) {
                    clearTimeout(nowplayAdvanceTimeoutRef.current);
                }

                nowplayAdvanceTimeoutRef.current = setTimeout(() => {
                    advanceState();
                    nowplayAdvanceTimeoutRef.current = null;
                }, nowplayStepDelay - (Date.now() - nowplayState.lastUpdateTime));
            }
        } else if (nowplayState.nowplayMoveCursor > maxMoveCursor) {
            // If we're ahead of the latest move, jump to the latest move
            console.log(maxMoveCursor + " Jumping backward to latest move");
            setNowplayState({
                ...nowplayState,
                nowplayMoveCursor: maxMoveCursor,
                lastUpdateTime: Date.now(),
            });
            const newState = game.game_hands[nowplayState.nowplayHandCursor].history![maxMoveCursor].state;
            playSoundForStateTransition(prevState, newState);
        }

        // Cleanup function to clear the timeout when the component unmounts or dependencies change
        return () => {
            if (nowplayAdvanceTimeoutRef.current) {
                clearTimeout(nowplayAdvanceTimeoutRef.current);
            }
        };
    }, [game, nowplayState, playerSeat, handHistoryCache, nowplayStepDelay]);

    // Set suggested plays
    useEffect(() => {
        if (
            game === null ||
            nowplayState.nowplayHandCursor === -1 ||
            nowplayState.nowplayMoveCursor === -1 ||
            game.game_hands.length <= nowplayState.nowplayHandCursor
        ) {
            return;
        }
        const history = game.game_hands[nowplayState.nowplayHandCursor].history;
        if (history === null || history.length <= nowplayState.nowplayMoveCursor) {
            return;
        }
        const playState = history[nowplayState.nowplayMoveCursor].state;
        if (
            playState.is_engine_turn ||
            playState.is_trade_phase ||
            playState.is_hand_over ||
            playState.give_dragon ||
            playState.make_wish ||
            playState.active_player !== table.players.findIndex((player) => player?.user?.id === user.id)
        ) {
            setSuggestedPlays([]);
        } else {
            const cardsReceived = [
                playState.positions[(playerSeat + 1) % 4].trade_slate![2],
                playState.positions[(playerSeat + 2) % 4].trade_slate![1],
                playState.positions[(playerSeat + 3) % 4].trade_slate![0],
            ];
            const suggestedPlays = MoveAssistant.suggestMoves(
                selectedCards,
                playState.positions[playState.active_player].hand_cards,
                [cardsReceived[0], cardsReceived[2]],
                [cardsReceived[1]],
                playState.winning_combination ?? undefined,
                playState.wish_rank ?? undefined
            );
            setSuggestedPlays(suggestedPlays);
        }
    }, [game, table, user, selectedCards, nowplayState, playerSeat]);

    const updateSettings = (settings: Partial<DmTableSettings>) => {
        apiClient.updateTableSettings(tableId, settings);
    };

    if (game === null || playerSeat === -1) {
        return <LoadingDiv>Loading...</LoadingDiv>;
    }

    const handIndex = replayState.replayModeEnabled ? replayState.replayHandCursor : nowplayState.nowplayHandCursor;
    const fullHandHistory = getHandHistory(game, handIndex, handHistoryCache);

    if (fullHandHistory === null) {
        return <LoadingDiv>Loading...</LoadingDiv>;
    }

    const startTable = () => {
        apiClient.startTable(tableId);
    };

    const addBot = (seat: number) => {
        apiClient.addBot(tableId, seat);
    };

    const removeBot = (seat: number) => {
        apiClient.removeBot(tableId, seat);
    };

    const changeSeat = (newSeat: number) => {
        apiClient.changeSeat(tableId, newSeat);
    };

    const leaveTable = () => {
        apiClient.leaveTable(tableId);
    };

    if (fullHandHistory.length === 0) {
        return (
            <TableSetup
                table={table}
                addBot={addBot}
                startTable={startTable}
                updateSettings={updateSettings}
                changeSeat={changeSeat}
                removeBot={removeBot}
                leaveTable={leaveTable}
            />
        );
    }

    const handHistory = fullHandHistory.slice(
        0,
        (replayState.replayModeEnabled ? replayState.replayMoveCursor : nowplayState.nowplayMoveCursor) + 1
    );
    const playState = handHistory[handHistory.length - 1].state;

    const isReplayOfFinishedHand =
        replayState.replayModeEnabled &&
        (game.game_hands.length > replayState.replayHandCursor + 1 ||
            game.game_hands[replayState.replayHandCursor].play_state.is_hand_over);

    const thisPlayerIsActive = playerSeat === playState.active_player;

    const isSpecialTurn = playState.give_dragon || playState.make_wish || playState.is_engine_turn;

    // Whenever game changes such that it's not the trade phase, if we have a trade slate, clear it
    if (!playState.is_trade_phase && tradeSlateCards.some((card) => card !== null)) {
        setTradeSlateCards([null, null, null]);
    }

    const getPlayFromSelectedCards = () => {
        if (playState.make_wish) {
            if (wishValue === undefined) {
                throw new Error("Wish value must be set before calling getPlayFromSelectedCards");
            }
            return MoveUtil.getMakeWishMove(playState.active_player, wishValue === "No wish" ? null : wishValue);
        }

        return MoveUtil.getMoveFromCards(playState.active_player, selectedCards, playState.winning_combination ?? undefined);
    };

    const nextHand = () => {
        if (game) {
            const response = apiClient.nextHand(table.id);
            response.then(() => {
                setSelectedCards([]);
                setPlayLimboCards([]);
            });
        }
    };

    const nextGame = () => {
        apiClient.newGame(tableId);
    };

    const bet = () => {
        playPop();
        apiClient.makeMove(table.id, MoveUtil.getBetMove(playState.active_player));
    };

    const submitTrade = () => {
        playPop();
        if (tradeSlateDirty) {
            if (tradeSlateCards.includes(null) || tradeSlateCards.length !== 3) {
                return;
            }
            setTradeSlateDirty(false);
            apiClient.chooseTrade(table.id, { position: playerSeat, trade_slate: tradeSlateCards } as DmTradeSlateChoice);
        }
    };

    const submitCancelTrade = () => {
        playDrum();
        setTradeSlateDirty(false);
        setTradeSlateCards([null, null, null]);
        apiClient.chooseTrade(table.id, { position: playerSeat, trade_slate: null } as DmTradeSlateChoice);
    };

    const playSuggestedCards = (suggestedPlay: DmCombination) => {
        playPop();
        const response = apiClient.makeMove(
            table.id,
            MoveUtil.getMoveFromCards(playState.active_player, suggestedPlay.cards, playState.winning_combination ?? undefined)
        );
        setWaitingOnSubmittedMove(true);
        setSelectedCards([]);
        setPlayLimboCards([...suggestedPlay.cards]);
        response.then(() => {
            setSelectedCards([]);
            setPlayLimboCards([]);
            setWishValue(undefined);
            setWaitingOnSubmittedMove(false);
        });
    };

    const playSelectedCards = () => {
        playPop();
        const response = apiClient.makeMove(table.id, getPlayFromSelectedCards());

        setWaitingOnSubmittedMove(true);
        setSelectedCards([]);
        setPlayLimboCards([...selectedCards]);
        // TODO: This should be on receiving the websocket event instead
        response.then(() => {
            setSelectedCards([]);
            setPlayLimboCards([]);
            setWishValue(undefined);
            setWaitingOnSubmittedMove(false);
        });
    };

    const toggleSelectCard = (cardId: CardId) => {
        const newSelectedCards = selectedCards.includes(cardId)
            ? selectedCards.filter((selectedCardId) => selectedCardId !== cardId)
            : selectedCards.concat(cardId);
        setSelectedCards(newSelectedCards);
    };

    const onCardClick = (cardId: CardId) => {
        if (playState.positions[playerSeat].hand_cards.includes(cardId)) {
            toggleSelectCard(cardId);
        }
    };

    const tradeSelect = (direction: "left" | "center" | "right") => {
        if (!playState.is_trade_phase) {
            return;
        }
        if (selectedCards.length === 1) {
            const newTradeSlateCards = [...tradeSlateCards];
            switch (direction) {
                case "left":
                    if (newTradeSlateCards[0] !== selectedCards[0]) {
                        newTradeSlateCards[0] = selectedCards[0];
                    }
                    break;
                case "center":
                    if (newTradeSlateCards[1] !== selectedCards[0]) {
                        newTradeSlateCards[1] = selectedCards[0];
                    }
                    break;
                case "right":
                    if (newTradeSlateCards[2] !== selectedCards[0]) {
                        newTradeSlateCards[2] = selectedCards[0];
                    }
                    break;
            }
            setTradeSlateDirty(true);
            setTradeSlateCards(newTradeSlateCards);
            setSelectedCards([]);
        } else {
            const newTradeSlateCards = [...tradeSlateCards];
            switch (direction) {
                case "left":
                    if (newTradeSlateCards[0] !== null) {
                        newTradeSlateCards[0] = null;
                    }
                    break;
                case "center":
                    if (newTradeSlateCards[1] !== null) {
                        newTradeSlateCards[1] = null;
                    }
                    break;
                case "right":
                    if (newTradeSlateCards[2] !== null) {
                        newTradeSlateCards[2] = null;
                    }
                    break;
            }
            setTradeSlateDirty(true);
            setTradeSlateCards(newTradeSlateCards);
            setSelectedCards([]);
        }
    };

    const seats = [];
    const activeAreas = [];
    let trickInfo = <></>;
    let controls = <></>;
    const isScore = playState.is_hand_over;
    const currentTrickMoves = MoveUtil.getPlayOrPassMovesFromCurrentTrick(handHistory);
    const lastMovesPerPlayer = [0, 1, 2, 3].map((player) => currentTrickMoves.filter((move) => move.position === player).pop());
    const seatsAndAlignments = [
        { perspectiveSeat: 0, seat: playerSeat, alignment: Alignment.BOTTOM },
        { perspectiveSeat: 1, seat: (playerSeat + 1) % 4, alignment: Alignment.LEFT },
        { perspectiveSeat: 2, seat: (playerSeat + 2) % 4, alignment: Alignment.TOP },
        { perspectiveSeat: 3, seat: (playerSeat + 3) % 4, alignment: Alignment.RIGHT },
    ];

    for (const { perspectiveSeat, seat, alignment } of seatsAndAlignments) {
        const i = seat;
        const handIsVisible = perspectiveSeat === 0 || isScore || isReplayOfFinishedHand;
        const filteredHandCards =
            perspectiveSeat !== 0
                ? playState.positions[i].hand_cards
                : playState.positions[i].hand_cards.filter((cardId) => tradeSlateCards.indexOf(cardId) === -1);
        const cardDetailsList = filteredHandCards.map((cardId) => (handIsVisible ? CardDetails.getCardDetails(cardId) : null));
        cardDetailsList.sort((a, b) => {
            if (a === null) {
                return 1;
            }
            if (b === null) {
                return -1;
            }
            return a.sortOrder - b.sortOrder;
        });

        const cards = cardDetailsList
            .filter((c) => c === null || playLimboCards.indexOf(c.cardId) === -1)
            .map((cardDetails, index) => {
                return (
                    <Card
                        key={cardDetails ? cardDetails.cardId : `unknown_card_${i}_${index}`}
                        cardDetails={cardDetails}
                        onClick={onCardClick}
                        selected={!!cardDetails && selectedCards.indexOf(cardDetails.cardId) !== -1}
                        textOnly={false}
                        contextId={table.id + handIndex}
                        sideAlignment={alignment === Alignment.LEFT ? "left" : alignment === Alignment.RIGHT ? "right" : undefined}
                    />
                );
            });
        const limboCards = cardDetailsList
            .filter((c) => c !== null && playLimboCards.indexOf(c.cardId) !== -1)
            .map((cardDetails, index) => {
                return (
                    <Card
                        key={cardDetails ? cardDetails.cardId : `unknown_card_${i}_${index}`}
                        cardDetails={cardDetails}
                        onClick={onCardClick}
                        selected={!!cardDetails && selectedCards.indexOf(cardDetails.cardId) !== -1}
                        textOnly={false}
                        contextId={table.id + handIndex}
                        inPlayLimbo={true}
                    />
                );
            });
        // TODO: reimplement hidden cards
        // for (let cardCount = cards.length; cardCount < cardLocations.byPlayer[i].handSize; cardCount++) {
        //     cards.push(
        //         <Card
        //             key={`unknown_card_${i}_${cardCount}`}
        //             cardDetails={CardDetails.UNKNOWN_CARD}
        //             onClick={() => {}}
        //             selected={false}
        //         />
        //     );
        // }
        const hand = (
            <Hand key={i} alignment={alignment}>
                {cards}
            </Hand>
        );
        const latestMove = lastMovesPerPlayer[i];
        const isThisPlayer = i === playerSeat;
        const isActivePlayer =
            (i === playState.active_player && !playState.is_trade_phase) ||
            (playState.is_trade_phase && playState.positions[i].trade_slate === null);
        const isWinningPlayer = i === playState.winning_player;
        const seatDiv = (
            <Seat
                player={table.players[i]}
                position={playState.positions[i]}
                active={isActivePlayer}
                alignment={alignment}
                hand={hand}
                limboCards={limboCards.length > 0 ? limboCards : undefined}
                nsTeam={i % 2 === 0}
            ></Seat>
        );
        seats.push(seatDiv);

        if (isThisPlayer && playState.is_trade_phase) {
            const activeAreaDiv = (
                <TradeArea>
                    <TradeCardSlot
                        cardId={tradeSlateCards[0]}
                        direction="left"
                        oneCardSelected={selectedCards.length === 1}
                        onClick={() => tradeSelect("left")}
                        contextId={table.id + handIndex}
                    ></TradeCardSlot>
                    <TradeCardSlot
                        cardId={tradeSlateCards[1]}
                        direction="center"
                        oneCardSelected={selectedCards.length === 1}
                        onClick={() => tradeSelect("center")}
                        contextId={table.id + handIndex}
                    ></TradeCardSlot>
                    <TradeCardSlot
                        cardId={tradeSlateCards[2]}
                        direction="right"
                        oneCardSelected={selectedCards.length === 1}
                        onClick={() => tradeSelect("right")}
                        contextId={table.id + handIndex}
                    ></TradeCardSlot>
                </TradeArea>
            );
            activeAreas.push(activeAreaDiv);
        } else {
            const activeAreaDiv = (
                <SeatActivePlay winning={isWinningPlayer}>
                    {latestMove &&
                        (latestMove.combination.combination_type === CombinationType.PASS ? (
                            <Pass />
                        ) : (
                            latestMove.combination.cards.map((cardId) => (
                                <Card
                                    key={cardId}
                                    cardDetails={CardDetails.getCardDetails(cardId)}
                                    onClick={onCardClick}
                                    selected={selectedCards.indexOf(cardId) !== -1}
                                    textOnly={false}
                                    contextId={table.id + handIndex}
                                />
                            ))
                        ))}
                    {latestMove &&
                        latestMove.combination.combination_type === CombinationType.SINGLE &&
                        latestMove.combination.cards[0] === CardId.PHOENIX && (
                            <div className="phoenixRankExplanation">({RANKS[latestMove.combination.rank]})</div>
                        )}
                </SeatActivePlay>
            );
            activeAreas.push(activeAreaDiv);
        }
    }

    trickInfo = <TrickInfo playState={playState}></TrickInfo>;

    // Whether the current player can bet
    const betAvailable =
        thisPlayerIsActive &&
        playState.positions[playState.active_player].bet === false &&
        playState.positions[playState.active_player].hand_cards.length === 14 &&
        !playState.positions.some((p) => p.finished_order === 1);

    const activeGameRequest = game.game_requests && game.game_requests.find((gameRequest) => gameRequest.pending);
    if (activeGameRequest) {
        const isOwnRequest = activeGameRequest.initiator.id === user.id;
        controls = (
            <Controls
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                transition={{
                    type: "spring",
                    stiffness: 800,
                    damping: 20,
                }}
                key={"gameRequestControls"}
            >
                <GameRequest request={activeGameRequest} game={game} players={table.players}></GameRequest>
                <RequestDeclineButton onClick={() => apiClient.denyRequest(table.id, activeGameRequest.id)}>
                    {isOwnRequest ? "Cancel" : "Decline"}
                </RequestDeclineButton>
                {!isOwnRequest && (
                    <RequestAcceptButton onClick={() => apiClient.approveRequest(table.id, activeGameRequest.id)}>
                        Accept
                    </RequestAcceptButton>
                )}
            </Controls>
        );
    } else if (!replayState.replayModeEnabled) {
        const isLead = playState.winning_player === null;
        controls = (
            <Controls
                layout={"position"}
                transition={{
                    duration: 0.1,
                }}
                key={"mainControls"}
            >
                <AnimatePresence>
                    {thisPlayerIsActive && playState.make_wish && (
                        <div className="wish-select" key="wish-select">
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-none"
                                value="None"
                                onChange={(e) => setWishValue("No wish")}
                            ></input>
                            <label htmlFor="wish-selection-none">None</label>
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-2"
                                value="2"
                                onChange={(e) => setWishValue(2)}
                            ></input>
                            <label htmlFor="wish-selection-2">2</label>
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-3"
                                value="3"
                                onChange={(e) => setWishValue(3)}
                            ></input>
                            <label htmlFor="wish-selection-3">3</label>
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-4"
                                value="4"
                                onChange={(e) => setWishValue(4)}
                            ></input>
                            <label htmlFor="wish-selection-4">4</label>
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-5"
                                value="5"
                                onChange={(e) => setWishValue(5)}
                            ></input>
                            <label htmlFor="wish-selection-5">5</label>
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-6"
                                value="6"
                                onChange={(e) => setWishValue(6)}
                            ></input>
                            <label htmlFor="wish-selection-6">6</label>
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-7"
                                value="7"
                                onChange={(e) => setWishValue(7)}
                            ></input>
                            <label htmlFor="wish-selection-7">7</label>
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-8"
                                value="8"
                                onChange={(e) => setWishValue(8)}
                            ></input>
                            <label htmlFor="wish-selection-8">8</label>
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-9"
                                value="9"
                                onChange={(e) => setWishValue(9)}
                            ></input>
                            <label htmlFor="wish-selection-9">9</label>
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-10"
                                value="10"
                                onChange={(e) => setWishValue(10)}
                            ></input>
                            <label htmlFor="wish-selection-10">10</label>
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-jack"
                                value="11"
                                onChange={(e) => setWishValue(11)}
                            ></input>
                            <label htmlFor="wish-selection-jack">J</label>
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-queen"
                                value="12"
                                onChange={(e) => setWishValue(12)}
                            ></input>
                            <label htmlFor="wish-selection-queen">Q</label>
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-king"
                                value="13"
                                onChange={(e) => setWishValue(13)}
                            ></input>
                            <label htmlFor="wish-selection-king">K</label>
                            <input
                                type="radio"
                                name="wish-selection"
                                id="wish-selection-ace"
                                value="14"
                                onChange={(e) => setWishValue(14)}
                            ></input>
                            <label htmlFor="wish-selection-ace">A</label>
                        </div>
                    )}

                    {thisPlayerIsActive && !playState.is_trade_phase && betAvailable && (
                        <motion.button
                            layout={"position"}
                            transition={{ duration: 0.1 }}
                            onClick={bet}
                            className="playCardsButton easyButton"
                            key="betControl"
                            disabled={waitingOnSubmittedMove}
                        >
                            {"Taichu!"}
                        </motion.button>
                    )}

                    {!(thisPlayerIsActive && playState.give_dragon) && !playState.is_trade_phase && (
                        <motion.button
                            layout={"position"}
                            transition={{ duration: 0.1 }}
                            onClick={playSelectedCards}
                            className="playCardsButton easyButton"
                            key="mainControl"
                            disabled={
                                waitingOnSubmittedMove ||
                                !thisPlayerIsActive ||
                                (isSpecialTurn && selectedCards.length > 0) ||
                                playState.give_dragon ||
                                (isLead && selectedCards.length === 0) ||
                                (playState.make_wish && wishValue === undefined)
                            }
                        >
                            {playState.make_wish ? "Wish" : selectedCards.length ? "Play" : "Pass"}
                        </motion.button>
                    )}

                    {suggestedPlays.length > 0 && (
                        <>
                            {suggestedPlays.map((suggestedPlay, index) => (
                                <motion.button
                                    layout
                                    transition={{ duration: 0.1 }}
                                    onClick={() => playSuggestedCards(suggestedPlay)}
                                    className="suggestedPlayButton easyButton"
                                    disabled={
                                        !thisPlayerIsActive ||
                                        (isSpecialTurn && selectedCards.length > 0) ||
                                        playState.give_dragon ||
                                        (isLead && selectedCards.length === 0)
                                    }
                                    key={`play_${index}`}
                                >
                                    {suggestedPlay.cards.map((cardId) => (
                                        <Card
                                            key={cardId}
                                            cardDetails={CardDetails.getCardDetails(cardId)}
                                            onClick={() => {}}
                                            selected={false}
                                            textOnly={true}
                                        />
                                    ))}
                                </motion.button>
                            ))}
                        </>
                    )}

                    {playState.is_trade_phase && playState.positions[playerSeat].trade_slate !== null && (
                        <button onClick={submitCancelTrade} className="cancelTradeButton easyButton" key={"tradeCancel"}>
                            {"Cancel Trade"}
                        </button>
                    )}

                    {playState.is_trade_phase && (
                        <button
                            onClick={submitTrade}
                            className="playCardsButton easyButton"
                            disabled={!tradeSlateDirty || tradeSlateCards.includes(null)}
                            key={"mainControl"}
                        >
                            {playState.positions[playerSeat].trade_slate !== null ? "Update Trade" : "Trade"}
                        </button>
                    )}

                    {thisPlayerIsActive && playState.give_dragon && (
                        <div style={{ display: "flex" }} key="mainControl">
                            <button
                                onClick={() => apiClient.makeMove(table.id, MoveUtil.getGiveDragonMove(playerSeat, true))}
                                className="playCardsButton easyButton"
                                disabled={!thisPlayerIsActive}
                            >
                                ←
                            </button>
                            <div className="giveDragonCenter">🐲</div>
                            <button
                                onClick={() => apiClient.makeMove(table.id, MoveUtil.getGiveDragonMove(playerSeat, false))}
                                className="playCardsButton easyButton"
                                disabled={!thisPlayerIsActive}
                            >
                                →
                            </button>
                        </div>
                    )}
                </AnimatePresence>
            </Controls>
        );
    } else {
        // replay mode
        if (game.status !== GameStatus.COMPLETE) {
            controls = (
                <Controls>
                    <button onClick={exitReplayMode} className="exitReplayButton easyButton" key="exitReplayButton">
                        Exit replay
                    </button>
                </Controls>
            );
        } else {
            controls = <></>;
        }
    }
    const cardsTradedToThisPlayer =
        !playState.is_trade_phase && !playState.positions.some((p) => p.trade_slate === null)
            ? [
                  playState.positions[(playerSeat + 1) % 4].trade_slate![2],
                  playState.positions[(playerSeat + 2) % 4].trade_slate![1],
                  playState.positions[(playerSeat + 3) % 4].trade_slate![0],
              ]
            : null;

    const nsScore = game.game_hands.map((hand) => (hand.play_state.is_hand_over ? hand.play_state.ns_score : 0)).reduce((a, b) => a + b, 0);
    const ewScore = game.game_hands.map((hand) => (hand.play_state.is_hand_over ? hand.play_state.ew_score : 0)).reduce((a, b) => a + b, 0);

    return (
        <div className="table">
            <LayoutGroup>
                <ReplayBar
                    replayState={replayState}
                    nowplayState={nowplayState}
                    setReplayState={setReplayState}
                    totalMoves={fullHandHistory.length - 1}
                    nsScore={nsScore}
                    ewScore={ewScore}
                    table={table}
                    handHistoryCache={handHistoryCache}
                    playerIsNs={playerSeat % 2 === 0}
                    gameIsOver={game.status === GameStatus.COMPLETE}
                    handleNextGame={nextGame}
                    leaveTable={leaveTable}
                    requestUndo={(hand: number, move: number) => apiClient.requestUndo(table.id, hand, move)}
                    requestAbort={() => apiClient.requestAbort(table.id)}
                />
                <div className="horizontalFlexbox">
                    <GameLayout seats={seats} activeAreas={activeAreas} trickInfo={trickInfo} controls={controls}></GameLayout>
                    <HistoryPanel
                        table={table}
                        handHistoryCache={handHistoryCache}
                        replayState={replayState}
                        nowplayState={nowplayState}
                        nextHandCallback={nextHand}
                        cardsGiven={playState.positions[playerSeat].trade_slate}
                        cardsReceived={cardsTradedToThisPlayer}
                    ></HistoryPanel>
                </div>
            </LayoutGroup>
        </div>
    );
};
